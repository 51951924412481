import { createSelector } from 'reselect'
import { UserType } from './reducers/User'

const Entry = (state) => state.entry

const getActiveEntry = createSelector(
  Entry,
  state => state.activeEntry || {},
)

// const getLanguage: (state: StateType) => LanguageType = createSelector(
const User: (state) => UserType = (state) => state.user
const getUserId = createSelector(
  User,
  user => user && user.user_id
)

// Tags selectors
const Tags = (state) => state.tags
const getUserTags = createSelector(
  Tags,
  tagData => tagData && tagData.tags
)

export { Entry, getActiveEntry, getUserId, User, getUserTags }