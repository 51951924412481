import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import styled from "styled-components";

import { createVideoPost } from "../../services";
import { Button } from "../../stories/Button";

const youtubeUrlPattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const InputRow = styled(Row)`
  flex-direction: column;
`;

type Inputs = {
  url: string;
  tags: string;
};

const StyledForm = styled.form`
  & > button {
    margin: 2rem 0 1rem;
  }

  & > div {
    display: flex;

    & > input.required {
      border: 1px solid #ff6460;
      border-width: 0 0 0 5px;
    }

    & > input,
    & > label {
      flex: 1;
      padding: 1rem 0.5rem;
      font-size: 1.3rem;
      border-radius: 0.5rem;
      border: none;
      background: rgba(0, 0, 0, 0.08);
      color: rgba(0, 0, 0, 0.7);

      &:focus {
        outline: none;
      }
    }
  }
`;

const FormErrorContainer = styled.div`
  height: 1rem;
  margin: 0.5rem 0 0.7rem 0.45rem;
  opacity: 0;
  transition: opacity 1s;
  font-weight: 600;
`;

const onSubmit: SubmitHandler<Inputs> = (data) => {
  createVideoPost({
    ...data,
    tags: !Array.isArray(data.tags)
      ? data.tags.split(",").map((t) => t.trim())
      : data.tags,
  });
};

export default function AddVideo() {
  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors, isSubmitting },
  } = useForm<Inputs>({
    mode: "onTouched",
  });

  return (
    <StyledForm noValidate onSubmit={handleSubmit(onSubmit)}>
      <InputRow>
        <input
          className={errors.url?.type === "required" ? "required" : ""}
          placeholder="https://youtube.com/..."
          {...register("url", {
            required: "url is required",
            pattern: {
              value: youtubeUrlPattern,
              message: "Must be a youtube url",
            },
          })}
        />
        <FormErrorContainer
          style={{ opacity: errors.url?.type === "pattern" ? 1 : 0 }}
        >
          {errors.url?.type === "pattern" && <span>{errors.url?.message}</span>}
        </FormErrorContainer>
      </InputRow>
      <InputRow>
        <input
          className={errors.tags?.type === "required" ? "required" : ""}
          placeholder="react, react-hooks, useState"
          {...register("tags", { required: "At least one tag is required" })}
        />
      </InputRow>
      <Button
        disabled={!isDirty || !isValid || isSubmitting}
        label="Post"
        primary
        size="medium"
        type="submit"
      />
    </StyledForm>
  );
}
