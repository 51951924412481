import React from 'react';
import { connect } from 'react-redux'

import {
  setNewPost,
  getRecentPosts,
  getCurrentUser,
  fetchUserTags,
  getUserPostsByTag,
} from '../../data/actions'
import { SingleEntryType } from '../../data/reducers/VideoEntries'
import { getUserTags } from '../../data/selectors'

import Dashboard from './template'

export interface UserMetaType {
  given_name: string;
}

interface MapStateType {
  videos: Array<SingleEntryType>,
  user: UserMetaType, 
  tags: string[],
}

interface MapDispatchType {
  onSubmit: (values: any, form: any) => void,
  getRecentPosts: () => void,
  getCurrentUser: (id: string, accessToken: string) => void,
  fetchUserTags: () => void,
  getUserPostsByTag: (tag: string) => void,
}

export type OwnProps = MapDispatchType & MapStateType

const DashboardContainer = (props: OwnProps) => <Dashboard {...props} />

const mapStateToProps = (state) => ({
  videos: state.entry.videos,
  user: state.user,
  tags: getUserTags(state),
})

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values: any, form) => {
    dispatch(setNewPost(values))
  },
  getRecentPosts: () => {
    dispatch(getRecentPosts())
  },
  fetchUserTags: () => {
    dispatch(fetchUserTags())
  },
  getCurrentUser: (id: string, accessToken: string) => {
    dispatch(getCurrentUser(id, accessToken))
  },
  getUserPostsByTag: (tag: string) => {
    dispatch(getUserPostsByTag(tag))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
