import React from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player'

import { SingleEntryType } from '../data/reducers/VideoEntries'

const VideoListContainer = styled.div`
  line-height: 170%;
  display: flex;
  flex-direction: row;
  overflow: auto;

  & > a {
    background: rgba(234, 196, 53, 0.4);
    border: 1px solid rgba(234, 196, 53, 0.6);
    border-radius: 0.2rem;
    padding: 0.1rem 0.2rem;
    display:block;
    color: rgba(0, 0, 0, 0.6);
    text-decoration: none;
    font-weight: 500;

    &:hover {
      color: rgba(0, 0, 0, 1);
      background: rgba(234, 196, 53, 1);
      border: 1px solid rgba(234, 196, 53, 2.4);
    }
  }
`

const VideoContainer = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  margin: 0 1rem 0 0;
  min-width: 320px;
`

interface PropType {
  items: SingleEntryType[],
}

const VideoList = ({ items }: PropType) => (
  <VideoListContainer>
    {items.map((item, i) => (
      <VideoContainer key={i}>
        <ReactPlayer
          light={item.mThumbnail}
          width={320}
          height={180}
          url={item.url}
          controls={true}
        />
      </VideoContainer>
    ))}
  </VideoListContainer>
)

export default VideoList