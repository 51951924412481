export interface TagsType {
  tags: string[],
  count: number,
}

const initialState: TagsType = {
  tags: [],
  count: 0,
}

const tags = (state: typeof initialState = initialState, action: any) => {
  switch (action.type) {
    case 'SET_USER_TAGS':
      const tags = action.payload.tags
      const count = tags.length
      return { ...state, tags, count }
    default:
      return state
  }
}

export default tags 