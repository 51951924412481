import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Logo = styled.div`
  a {
    color: rgba(52, 89, 149, 0.8);
    font-size: 1.5rem;
    text-decoration: none;
    font-weight: 800;
    text-transform: uppercase;
  }
`;

const TupleParen = styled.span`
  color: rgba(52, 89, 149, 0.1);
  display: inline-block;
  transform: translateY(-0.1rem);
`;

const LogoContainer = () => {
  return (
    <Logo>
      <a href={`/`} className="logo">
        <TupleParen>{"("}</TupleParen>
        tuple.tv
        <TupleParen>{")"}</TupleParen>
      </a>
    </Logo>
  );
};

export default LogoContainer;
