import React, { useEffect } from "react";
import { connect, MapDispatchToProps } from "react-redux";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.css";
import Homepage from "./scenes/Homepage";
import Dashboard from "./scenes/Dashboard";
import Tag from "./scenes/Tag";
import Video from "./scenes/Video";
import { history } from "./index";
import { getCurrentUser } from "./data/actions";
import Loader from "./components/Loader";

const ProtectedRoute = ({ component, ...args }) => (
  <Route component={withAuthenticationRequired(component)} {...args} />
);

interface MapDispatchType {
  getCurrentUser: (id: string, accessToken: string) => void;
}

function App({ getCurrentUser }: MapDispatchType) {
  const {
    error,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    user,
  } = useAuth0();

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = "dev-w3slgc8h.auth0.com";

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        });

        getCurrentUser(user, accessToken);
      } catch (e) {
        console.log(e.message);
      }
    };

    if (isAuthenticated) {
      getUserMetadata();
    }
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <Router history={history}>
      <Switch>
        <Route path="/post/:id" children={<Video />} />
        <Route path="/videos/:tag" component={Tag} />
        <ProtectedRoute path="/users/videos/:tag" component={Dashboard} />
        <ProtectedRoute path="/dashboard" component={Dashboard} />
        <Route path="/" component={Homepage} />
      </Switch>
    </Router>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: (id: string, accessToken: string) => {
    dispatch(getCurrentUser(id, accessToken));
  },
});

export default connect(null, mapDispatchToProps)(App);
