import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

import { Tag } from "../../components";
import { Row, Column } from "../Dashboard/template";
import { Container } from "../Dashboard/template";
import Header from "../Header";
import VideoDisplay from "../Dashboard/VideoDisplay";

interface RouteParams {
  id: string;
}

const VideoContainer = styled.div`
  grid-column-start: content1;
  grid-column-end: content2;
  width: 52.5rem;
`;

const Title = styled.h2`
  grid-row-start: footer;
  grid-column-start: content1;
  grid-column-end: content2;
`;

const Video = ({ activeEntry, fetchPost }) => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user: savedUser,
  } = useAuth0();

  let { id } = useParams<RouteParams>();
  useEffect(() => {
    fetchPost(id);
  }, [id]);

  let history = useHistory();

  return (
    <Container>
      <Header />
      <VideoContainer>
        <ReactPlayer
          className="react-player"
          width="100%"
          height="100%"
          url={activeEntry.url}
          controls={true}
          onSeek={(e) => {
            console.log("onSeek", e);
          }}
          onStart={() => {}}
        />
      </VideoContainer>
      <Title>{activeEntry.text}</Title>
      {/* {activeEntry.tags.map((tag, i) => (
        <Tag key={i} text={tag} linkTo={`/users/videos/${tag}`} />
      ))} */}
    </Container>
  );
};

export default Video;
