import { SingleEntryType } from './VideoEntries'


export interface ListType {
  items: SingleEntryType[],
  title: string,
}

interface ListsType {
  lists: ListType[],
}

const initialState: ListsType = {
  lists: [],
}

const homepage = (state: typeof initialState = initialState, action: any) => {
  switch (action.type) {
    case 'SET_HOMEPAGE_LIST':
      const { list } = action.payload
      const lists = state.lists.filter(l => l.title === list.title)

      if (lists.length) {
        return state
      } else {
        return {
          ...state,
          lists: [action.payload.list, ...state.lists],
        }
      }
    default:
      return state
  }
}

export default homepage