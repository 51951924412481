import React, { useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import Logo from "../../components/Logo";
import VideoList from "../../components/VideoList";
import Header from "../Header";
import { OwnProps as _O } from ".";

const Container = styled.div`
  display: grid;
  grid-template-areas:
    "header"
    "content"
    "footer";
  grid-template-rows: 4rem 1fr 3rem;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.02);
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Column = styled.section`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

const ListRow = styled.div`
  margin: 1rem;

  & h2 {
    margin: 0 0 0.5rem;
  }
`;

const Footer = styled(Row)`
  border-top: 1px solid rgba(239, 239, 239, 0.93725);
  background: #fff;
  grid-row-start: footer;
  grid-row-end: footer;
  grid-column-start: footer;
  grid-column-end: footer;
  justify-content: center;
  align-items: center;
`;

interface RouteParams {
  tag: string;
}

const Homepage = ({ getPostsByTag, lists }: _O) => {
  let { tag } = useParams<RouteParams>();
  useEffect(() => {
    if (!lists.length) {
      getPostsByTag(tag);
    }
  }, [getPostsByTag, lists.length, tag]);

  return (
    <Container>
      <Header />
      <Column>
        {lists &&
          lists.map((list, i) => (
            <ListRow key={i}>
              <h2>#{list.title}</h2>
              <VideoList items={list.items} />
            </ListRow>
          ))}
      </Column>
      <Footer>
        Copyright 2021 - <Logo />
      </Footer>
    </Container>
  );
};

export default Homepage;
