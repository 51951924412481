import React from 'react';
import styled from 'styled-components'
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom';

import Logo from '../../components/Logo'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Header = styled(Row)`
  border-bottom: 1px solid rgba(239,239,239,0.93725);
  background: #fff;
  padding: 0 1rem;
  grid-row-start: header;
  grid-row-end: header;
  grid-column-start: header;
  grid-column-end: header;

  align-items: center;
`

const UserOptions = styled.div`
  width: 10rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const UserPhoto = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: 0.0625rem solid rgba(0, 0, 0, 0.3);
  overflow: hidden;
  margin-left: 1rem;

  img {
    height: 2rem;
    width: 2rem;
  }
`

const StyledButton = styled.button`
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  text-decoration: underline;
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
  }

  &:active, &:focus {
    outline: none;
  }
`

const LoginButton = styled(StyledButton)`
`

const SignoutButton = styled(StyledButton)`
`

const HeaderPromotional = styled.div`
  display: flex;
  align-items: center;

  & h2 {
    margin: 0 0 0.1rem 1rem;
    font-size: 0.9rem;
    font-weight: 600;
    align-self: flex-end;
    color: rgba(0,0,0,0.8);
  }
`

const HeaderContainer = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user: savedUser,
  } = useAuth0();

  return (
    <Header>
      <HeaderPromotional>
        <Logo />
        <h2>Watch, Save &amp; Tag Coding Videos</h2>
      </HeaderPromotional>
      {(isAuthenticated && savedUser)
        ? (
            <UserOptions>
              <SignoutButton
                onClick={() => 
                  logout({ returnTo: window.location.origin })}
              >
                Sign out
              </SignoutButton>
              <UserPhoto>
                <Link to={'/dashboard'}>
                  <img src={savedUser.picture} />
                </Link>
              </UserPhoto>
            </UserOptions>
          )
        : (
            <UserOptions>
              <LoginButton
                onClick={loginWithRedirect}
              >
                Login/Signup
              </LoginButton>
            </UserOptions>
        )}
    </Header>  
  )
}

export default HeaderContainer
