import { SagaIterator } from "redux-saga";
import { takeEvery, put, call, select } from "redux-saga/effects";
import * as selectors from "./selectors";
import * as actions from "./actions";

import {
  createVideoPost,
  fetchDeletePost,
  getVideoPosts,
  getPost,
  getCurrentUser,
  getUserTags,
  getUserVideosByTag,
  getVideosByTag,
} from "../services";
import { SingleEntryAT, FetchPostAT } from "./reducers/VideoEntries";
import { access } from "fs";

/*
 * Video Entry sagas
 */
function* saveEntry(action: SingleEntryAT) {
  const userId = yield select(selectors.getUserId);
  const { tags } = action.payload;
  const body = {
    ...action.payload,
    tags: !Array.isArray(tags) ? tags.split(",").map((t) => t.trim()) : tags,
    userId,
  };
  const payload = yield call(createVideoPost, body);
  yield put(actions.getRecentPosts());
  yield put(actions.fetchUserTags());
}

function* fetchRecentPosts() {
  const videos = yield call(getVideoPosts);
  yield put({ type: "SET_VIDEOS", payload: { videos } });
}

function* fetchPost(action: FetchPostAT) {
  const { id } = action.payload;
  const payload = yield call(getPost, id);
  yield put({ type: "SET_ACTIVE_ENTRY", payload });
}

/*
 * User sagas
 */
function* fetchCurrentUser(action: any) {
  try {
    const domain = "dev-w3slgc8h.auth0.com";
    const { accessToken, user } = action.payload;

    const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
    let userMetadata = yield fetch(userDetailsByIdUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    userMetadata = yield userMetadata.json();
    yield put({ type: "SET_USER", payload: { ...userMetadata, accessToken } });
    // yield put(actions.fetchUserTags())
  } catch (e) {
    console.log(e.message);
  }
}

function* fetchUserTags() {
  const userId = yield select(selectors.getUserId);
  const { accessToken } = yield select(selectors.User);
  const payload = yield call(getUserTags, userId);
  yield put({ type: "SET_USER_TAGS", payload });
}

function* getPostsByTag(action: any) {
  const { tag: title } = action.payload;
  const items = yield call(getVideosByTag, title);
  const list = { title, items };
  yield put({ type: "SET_HOMEPAGE_LIST", payload: { list } });
}

function* getUserPostsByTag(action: any) {
  const userId = yield select(selectors.getUserId);
  const { tag } = action.payload;
  const videos = yield call(getUserVideosByTag, userId, tag);
  yield put({ type: "SET_VIDEOS", payload: { videos } });
}

function* deletePost(action: ReturnType<typeof actions.deletePost>) {
  const { id } = action.payload;
  yield call(fetchDeletePost, id);
  yield put({ type: "FETCH_RECENT_POSTS" });
}

export default function* rootSaga(): SagaIterator {
  yield takeEvery("SET_NEW_POST", saveEntry);
  yield takeEvery("FETCH_RECENT_POSTS", fetchRecentPosts);
  yield takeEvery("FETCH_POST", fetchPost);
  yield takeEvery("GET_POSTS_BY_TAG", getPostsByTag);
  yield takeEvery("GET_USER_POSTS_BY_TAG", getUserPostsByTag);
  yield takeEvery("FETCH_CURRENT_USER", fetchCurrentUser);
  yield takeEvery("FETCH_USER_TAGS", fetchUserTags);
  yield takeEvery("DELETE_POST", deletePost);
}
