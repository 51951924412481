export interface SingleEntryType {
  channelId?: string;
  checkpoints?: {
    firstCode?: string;
  };
  createdAt: string;
  maxThumbnail?: string;
  mThumbnail?: string;
  publishedAt?: string;
  tags: string | string[];
  text?: string;
  updatedAt: string;
  url: string;
  userIds: string[];
  videoId?: string;
  _id: string;
}

export interface EntryListType {
  videos: Array<SingleEntryType>;
}

export interface SingleEntryAT {
  type: "SET_NEW_VIDEO";
  payload: SingleEntryType;
}

export interface FetchPostAT {
  type: "FETCH_POST";
  payload: { id: string };
}

export interface EntryListAT {
  type: "SET_VIDEOS";
  payload: EntryListType;
}

export interface ActiveEntryAT {
  type: "SET_ACTIVE_ENTRY";
  payload: SingleEntryType;
}

export type EntryAT = SingleEntryAT | EntryListAT | ActiveEntryAT;

interface VideoEntriesType {
  videos: SingleEntryType[] | [];
  activeEntry: SingleEntryType | {};
}

const initialState: VideoEntriesType = {
  videos: [],
  activeEntry: {},
};

const entry = (state: VideoEntriesType = initialState, action: EntryAT) => {
  switch (action.type) {
    case "SET_NEW_VIDEO":
      const { url, tags } = action.payload;
      return { ...state, videos: [{ url, tags }, ...state.videos] };
    case "SET_VIDEOS":
      const { videos } = action.payload;
      return { ...state, videos };
    case "SET_ACTIVE_ENTRY":
      const activeEntry = action.payload;
      return { ...state, activeEntry };
    default:
      return state;
  }
};

export default entry;
