import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const TagContainer = styled.div`
  line-height: 170%;
  margin: 0 0.4rem 0.4rem 0;

  & > a {
    background: rgba(234, 196, 53, 0.4);
    border: 1px solid rgba(234, 196, 53, 0.6);
    border-radius: 0.2rem;
    padding: 0.1rem 0.2rem;
    display: block;
    color: rgba(0, 0, 0, 0.6);
    text-decoration: none;
    font-weight: 500;
    transition: background 0.3s;

    &:hover {
      color: rgba(0, 0, 0, 1);
      background: rgba(234, 196, 53, 1);
      border: 1px solid rgba(234, 196, 53, 2.4);
    }
  }
`;

const Hash = styled.span`
  color: rgba(0, 0, 0, 0.3);
`;

interface PropType {
  text: string;
  linkTo: string;
}

const Tag = ({ text, linkTo }: PropType) => (
  <>
    <TagContainer>
      <Link to={linkTo}>
        <Hash>#</Hash>
        {text}
      </Link>
    </TagContainer>
  </>
);

export default Tag;
