const API_DOMAIN = "https://api.tuple.tv";
const LS_ITEM_NAME = `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::${process.env.REACT_APP_AUTH0_AUDIENCE}::${process.env.REACT_APP_AUTH0_SCOPE}`;

interface CreatePostType {
  url: string;
  tags: string[];
}
const createVideoPost = (body: CreatePostType) => {
  const authedUser = localStorage.getItem(LS_ITEM_NAME);
  const parsedUser = JSON.parse(authedUser || "{}");
  return fetch(`${API_DOMAIN}/videos`, {
    method: "POST",
    redirect: "follow",
    referrerPolicy: "no-referrer-when-downgrade",
    headers: {
      Authorization: `Bearer ${parsedUser.body.access_token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
      Connection: "keep-alive",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch(console.error);
};

const getVideoPosts = () => {
  const authedUser = localStorage.getItem(LS_ITEM_NAME);
  const parsedUser = JSON.parse(authedUser || "{}");
  return fetch(`${API_DOMAIN}/videos`, {
    method: "GET",
    redirect: "follow",
    referrerPolicy: "no-referrer-when-downgrade",
    headers: {
      Authorization: `Bearer ${parsedUser.body.access_token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
      Connection: "keep-alive",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch(console.error);
};

const getPost = (id: string) =>
  fetch(`${API_DOMAIN}/videos/${id}`, {
    method: "GET",
    redirect: "follow",
    referrerPolicy: "no-referrer-when-downgrade",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Connection: "keep-alive",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch(console.error);

const getCurrentUser = (userId: string, accessToken: string) => {
  const authedUser = localStorage.getItem(LS_ITEM_NAME);
  const parsedUser = JSON.parse(authedUser || "{}");
  return fetch(`https://dev-w3slgc8h.auth0.com/api/v2/users/${userId}`, {
    method: "GET",
    redirect: "follow",
    referrerPolicy: "no-referrer-when-downgrade",
    headers: {
      Authorization: `Bearer ${parsedUser.body.access_token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
      Connection: "keep-alive",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch(console.error);
};

const getUserTags = (userId: string) => {
  const authedUser = localStorage.getItem(LS_ITEM_NAME);
  const parsedUser = JSON.parse(authedUser || "{}");
  return fetch(`${API_DOMAIN}/users/${userId}/tags`, {
    method: "GET",
    redirect: "follow",
    referrerPolicy: "no-referrer-when-downgrade",
    headers: {
      Authorization: `Bearer ${parsedUser.body.access_token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
      Connection: "keep-alive",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch(console.error);
};

const getVideosByTag = (tag: string) =>
  fetch(`${API_DOMAIN}/videos/tags/${tag}`, {
    method: "GET",
    redirect: "follow",
    referrerPolicy: "no-referrer-when-downgrade",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Connection: "keep-alive",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch(console.error);

const getUserVideosByTag = (userId: string, tag: string) => {
  const authedUser = localStorage.getItem(LS_ITEM_NAME);
  const parsedUser = JSON.parse(authedUser || "{}");
  return fetch(`${API_DOMAIN}/users/${userId}/tags/${tag}`, {
    method: "GET",
    redirect: "follow",
    referrerPolicy: "no-referrer-when-downgrade",
    headers: {
      Authorization: `Bearer ${parsedUser.body.access_token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
      Connection: "keep-alive",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch(console.error);
};

const fetchDeletePost = (id: string) => {
  const authedUser = localStorage.getItem(LS_ITEM_NAME);
  const parsedUser = JSON.parse(authedUser || "{}");
  return fetch(`${API_DOMAIN}/videos/${id}`, {
    method: "DELETE",
    redirect: "follow",
    referrerPolicy: "no-referrer-when-downgrade",
    headers: {
      Authorization: `Bearer ${parsedUser.body.access_token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
      Connection: "keep-alive",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch(console.error);
};

export {
  createVideoPost,
  getVideoPosts,
  getPost,
  getCurrentUser,
  getUserTags,
  getVideosByTag,
  getUserVideosByTag,
  fetchDeletePost,
};
