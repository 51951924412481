import React from "react";
import { connect } from "react-redux";

import {
  setNewPost,
  getRecentPosts,
  getCurrentUser,
  fetchUserTags,
  getPostsByTag,
} from "../../data/actions";
import { SingleEntryType } from "../../data/reducers/VideoEntries";
import { ListType } from "../../data/reducers/Homepage";
import { getUserTags } from "../../data/selectors";

import Tag from "./template";

export interface UserMetaType {
  given_name: string;
}

interface MapStateType {
  videos: SingleEntryType[];
  user: UserMetaType;
  tags: string[];
  lists: ListType[];
}

interface MapDispatchType {
  onSubmit: (values: any) => void;
  getRecentPosts: () => void;
  getCurrentUser: (id: string, accessToken: string) => void;
  fetchUserTags: () => void;
  getPostsByTag: (tag: string) => void;
}

export type OwnProps = MapDispatchType & MapStateType;

const TagContainer = (props: OwnProps) => <Tag {...props} />;

const mapStateToProps = (state) => ({
  videos: state.entry.videos,
  user: state.user,
  tags: getUserTags(state),
  lists: state.homepage.lists,
});

const mapStateToDispatch = (dispatch) => ({
  onSubmit: (values: any) => {
    dispatch(setNewPost(values));
  },
  getRecentPosts: () => {
    dispatch(getRecentPosts());
  },
  fetchUserTags: () => {
    dispatch(fetchUserTags());
  },
  getCurrentUser: (id: string, accessToken: string) => {
    dispatch(getCurrentUser(id, accessToken));
  },
  getPostsByTag: (tag: string) => {
    dispatch(getPostsByTag(tag));
  },
});

export default connect(mapStateToProps, mapStateToDispatch)(TagContainer);
