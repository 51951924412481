import React from "react";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { Link, RouteComponentProps } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";

import { Tag } from "../../components";
import { deletePost } from "../../data/actions";
import { SingleEntryType } from "../../data/reducers/VideoEntries";
import { Column } from "./template";
import { Button } from "../../stories/Button";

export const StyledRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 7rem 0rem 2rem;
`;

const StyledDate = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
  margin: 0 0 0.3rem;
  text-transform: capitalize;
  width: 100%;
`;

const StyledTitle = styled.div`
  & > a {
    text-decoration: none;
    color: rgba(41, 41, 41, 1);
    font-size: 1rem;
    font-weight: 700;
  }
`;

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  height: 0;

  & .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
`;

type MapDispatchType = {
  deletePost: (id: string) => void;
};

interface OwnPropsType {
  size: "xs" | "sm" | "md" | "lg" | "xl";
  redirectOnDelete?: () => void;
}

type PropsType = RouteComponentProps &
  SingleEntryType &
  OwnPropsType & { isAuthenticated: boolean } & MapDispatchType;

type StateType = {
  started: boolean;
};
class VideoDisplay extends React.Component<PropsType, StateType> {
  state = {
    started: false,
  };
  player;
  ref = (player) => {
    this.player = player;
  };

  render() {
    const {
      publishedAt,
      deletePost,
      _id,
      isAuthenticated,
      mThumbnail,
      maxThumbnail,
      size,
      url,
      text,
      tags,
      checkpoints,
      redirectOnDelete = () => {},
    } = this.props;
    let height = 50;
    let width = 70;
    switch (size) {
      case "xs":
        break;
      case "sm":
        height = 100;
        width = 150;
        break;
      case "md":
        height = 318;
        width = 566;
        break;
      case "lg":
        height = 300;
        width = 350;
        break;
      case "xl":
        height = 480;
        width = 640;
        break;
    }

    const videoDate = publishedAt ? new Date(publishedAt) : new Date();
    const jumpToCode = () => {
      this.player.seekTo(((checkpoints?.firstCode as unknown) as number) || 0);
    };
    return (
      <div>
        <Column>
          <PlayerWrapper>
            <ReactPlayer
              className="react-player"
              ref={this.ref}
              light={maxThumbnail || mThumbnail}
              width="100%"
              height="100%"
              url={url}
              controls={true}
              onSeek={(e) => {
                console.log("onSeek", e);
              }}
              onStart={() => {
                this.setState({ started: true });
              }}
            />
          </PlayerWrapper>
          <ControlsContainer>
            <Button
              label="Jump to Code"
              onClick={jumpToCode}
              primary
              size="medium"
              disabled={!this.state.started || !checkpoints?.firstCode}
            />
            {isAuthenticated && (
              <Button
                label="Delete"
                onClick={() => {
                  deletePost(_id);
                  setTimeout(() => {
                    redirectOnDelete();
                  }, 2000);
                }}
                size="medium"
              />
            )}
          </ControlsContainer>
          <StyledRow>
            <StyledDate>
              {formatDistanceToNow(videoDate, { addSuffix: true })}
            </StyledDate>
            <StyledTitle>
              <Link to={`/post/${_id}`}>{text}</Link>
            </StyledTitle>
          </StyledRow>
          <StyledRow>
            {Array.isArray(tags) &&
              tags.map((tag, i) => (
                <Tag key={i} text={tag} linkTo={`/users/videos/${tag}`} />
              ))}
          </StyledRow>
        </Column>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  deletePost: (_id: string) => dispatch(deletePost(_id)),
});

export default connect(null, mapDispatchToProps)(VideoDisplay);
