import React from "react";
import styled from "styled-components";

const Loading = styled.div`
  flex: 1;
  align-self: center;
  text-align: center;
  animation: 0.8s ease-in infinite alternate fadeIn;

  &::before {
    color: rgba(52, 89, 149, 0.8);
    font-size: 2rem;
    content: "( T )";
  }

  @keyframes fadeIn {
    from {
      opacity: 0.05;
    }
    to {
      opacity: 1;
    }
  }
`;

export default Loading;
