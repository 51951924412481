import React from 'react'
import { connect } from 'react-redux'
import { SingleEntryType } from '../../data/reducers/VideoEntries'
import { fetchPost } from '../../data/actions'
import { getActiveEntry } from '../../data/selectors'

import Video from './template'

interface DispatchPropsType {
  fetchPost: (id: string) => void
}

interface StatePropsType {
  activeEntry: SingleEntryType
}

export type PropsType = StatePropsType & DispatchPropsType

const VideoContainer = (props: PropsType) => {
  return <Video {...props} />
}

const mapStateToProps = (state) => ({
  activeEntry: getActiveEntry(state),
})

const mapStateToDispatch = (dispatch) => ({
  fetchPost: (id: string) => {
    dispatch(fetchPost(id))
  },
})

export default connect(mapStateToProps, mapStateToDispatch)(VideoContainer)