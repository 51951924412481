export interface UserType {
  given_name: string
  user_id: string
  accessToken?: string
}

const initialState: UserType | {} = {}

const user = (state: typeof initialState = initialState, action: any) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...action.payload }
    default:
      return state
  }
}

export default user