import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Loader from "../../components/Loader";
import { OwnProps as _O } from ".";

import VideoDisplay from "./VideoDisplay";
import Header from "../Header";
import Logo from "../../components/Logo";
import AddVideo from "./AddVideo";
import { Tag } from "../../components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    "header header header"
    "content1 content2 content3"
    "footer footer footer";
  grid-template-rows: 4rem 1fr 3rem;
  grid-template-columns: 20rem 1fr 19rem;
  grid-gap: 1rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.02);

  & h2 {
    margin-top: 0;

    & span {
      background: #eee;
      color: #aaa;
      padding: 3px 5px;
      font-size: 0.5rem;
      border-radius: 3px;
      display: inline-block;
      transform: translate(0, -4px);
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  &:nth-child(2) {
    grid-area: content1;
    padding-left: 1rem;
  }

  &:nth-child(3) {
    grid-area: content2;
  }

  &:nth-child(4) {
    grid-area: content3;
    padding-right: 1rem;
  }
`;

const VideoList = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 35rem;
`;

const PostContainer = styled.div`
  border: 1px solid rgba(239, 239, 239, 0.93725);
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: #fff;
  margin: 0 0 1rem;
  padding: 0 0 1rem;
  width: 49%;
  min-width: 17rem;
`;

const TagsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

const JobsPromo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem;

  & > div {
    display: flex;
    flex-direction: column;
  }

  & img {
    height: 4rem;
    width: 4rem;
  }

  & h3,
  & h4 {
    margin: 0;

    & a {
      color: black;
      text-decoration: none;

      &:hover {
        color: rgba(52, 89, 149, 0.8);
      }
    }
  }

  & h4 {
    font-weight: 400;
  }
`;

const Footer = styled(Row)`
  border-top: 1px solid rgba(239, 239, 239, 0.93725);
  background: #fff;
  grid-row-start: footer;
  grid-row-end: footer;
  grid-column-start: footer;
  grid-column-end: footer;
  justify-content: center;
  align-items: center;
`;

interface RouteParams {
  tag: string;
}

const Dashboard = ({
  videos,
  getRecentPosts,
  getUserPostsByTag,
  getCurrentUser,
  fetchUserTags,
  user,
  tags,
}: _O) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  let { tag } = useParams<RouteParams>();

  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    error,
    logout,
    user: savedUser,
  } = useAuth0();

  useEffect(() => {
    if (!tag) {
      const getUserMetadata = async () => {
        const domain = "dev-w3slgc8h.auth0.com";
        try {
          const accessToken = await getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
          });

          setAccessToken(accessToken);
          getCurrentUser(savedUser, accessToken);
        } catch (e) {
          console.log(e.message);
        }
      };
      getUserMetadata().then(() => {
        fetchUserTags();
        getRecentPosts();
      });
    } else {
      fetchUserTags();
      getUserPostsByTag(tag);
    }
  }, []);

  return (
    <Container>
      <Header />
      <Column>
        <h2>Add video</h2>
        <AddVideo />
        <TagsContainer>
          {tags.map((tag, i) => (
            <Tag key={i} text={tag} linkTo={`/users/videos/${tag}`} />
          ))}
        </TagsContainer>
      </Column>
      <Column>
        <h2 style={{ width: "100%" }}>Your Videos</h2>
        <VideoList>
          {videos && videos.length ? (
            videos.map((video, i) => (
              <PostContainer key={i}>
                <VideoDisplay
                  isAuthenticated={isAuthenticated}
                  size="xl"
                  {...video}
                />
              </PostContainer>
            ))
          ) : (
            <div
              style={{
                textAlign: "left",
                margin: "4rem 1rem 0 0",
                width: "30rem",
              }}
            >
              <Loader />
            </div>
          )}
        </VideoList>
      </Column>
      <Column>
        <h2>
          Jobs in Tech <span style={{ display: "none" }}>Sponsored</span>
        </h2>
        <JobsPromo>
          <a href="https://boards.greenhouse.io/blockchain/jobs/1238688?ref=tuple.tv">
            <img alt="Blockchain.com logo" src="/blockchain-icon.svg" />
          </a>
          <div>
            <h3>
              <a href="https://boards.greenhouse.io/blockchain/jobs/1238688?ref=tuple.tv">
                Blockchain.com
              </a>
            </h3>
            <h4>
              <a href="https://boards.greenhouse.io/blockchain/jobs/1238688?ref=tuple.tv">
                Front End Engineer
              </a>
            </h4>
          </div>
        </JobsPromo>
        <JobsPromo>
          <a href="https://stripe.com/jobs/listing/backend-api-engineer-cards/2283100?ref=tuple.tv">
            <img alt="Stripe.com logo" src="/stripe-icon.svg" />
          </a>
          <div>
            <h3>
              <a href="https://stripe.com/jobs/listing/backend-api-engineer-cards/2283100?ref=tuple.tv">
                Stripe.com
              </a>
            </h3>
            <h4>
              <a href="https://stripe.com/jobs/listing/backend-api-engineer-cards/2283100?ref=tuple.tv">
                Backend / API Engineer, Cards
              </a>
            </h4>
          </div>
        </JobsPromo>
        <JobsPromo>
          <a href="https://codesandbox.recruitee.com/o/senior-react-uiux-engineer?ref=tuple.tv">
            <img alt="Codesandbox.io logo" src="/codesandbox-icon.svg" />
          </a>
          <div>
            <h3>
              <a href="https://codesandbox.recruitee.com/o/senior-react-uiux-engineer?ref=tuple.tv">
                Codesandbox.io
              </a>
            </h3>
            <h4>
              <a href="https://codesandbox.recruitee.com/o/senior-react-uiux-engineer?ref=tuple.tv">
                Senior React UI/UX Engineer
              </a>
            </h4>
          </div>
        </JobsPromo>
      </Column>
      <Footer>
        Copyright 2021 -
        <Logo />
      </Footer>
    </Container>
  );
};

export default Dashboard;
