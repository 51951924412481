/*
 * Video Entry actions
 */
export const setNewPost = (values) => ({
  type: 'SET_NEW_POST',
  payload: values,
})

export const getRecentPosts = () => ({
  type: 'FETCH_RECENT_POSTS'
})

export const getPostsByTag = (tag) => ({
  type: 'GET_POSTS_BY_TAG',
  payload: { tag },
})

export const getUserPostsByTag = (tag) => ({
  type: 'GET_USER_POSTS_BY_TAG',
  payload: { tag },
})

export const fetchPost = (id) => ({
  type: 'FETCH_POST',
  payload: { id },
})

export const deletePost = (id) => ({
  type: 'DELETE_POST',
  payload: { id },
})


/*
 * User actions
 */
export const getCurrentUser = (user, accessToken) => ({
  type: 'FETCH_CURRENT_USER',
  payload: { user, accessToken },
})


/*
 * Tags actions
 */
export const fetchUserTags = () => ({
  type: 'FETCH_USER_TAGS',
})

export const setUserTags = (tags: string[]) => ({
  type: 'SET_USER_TAGS',
  payload: { tags },
})